import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import React from "react";

export const RoundedCheckmarkIcon = (props: React.ComponentProps<typeof StyledSvg> & { $disabled: boolean }) => {
  const [_, $theme] = useStyletron();

  return (
    <StyledSvg viewBox="0 0 16 16" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4571 4.98857C13.8476 5.3791 13.8476 6.01226 13.4571 6.40279L6.84841 13.0115C6.45789 13.402 5.82472 13.402 5.4342 13.0115L2.54289 10.1202C2.15237 9.72965 2.15237 9.09649 2.54289 8.70596C2.93342 8.31544 3.56658 8.31544 3.95711 8.70596L6.1413 10.8902L12.0429 4.98857C12.4334 4.59805 13.0666 4.59805 13.4571 4.98857Z"
        fill={props.color ?? (props.$disabled ? $theme.colors.contentStateDisabled : "currentcolor")}
      />
    </StyledSvg>
  );
};
