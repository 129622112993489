import { BannerKind } from "@gadgetinc/widgets";
import { observer } from "mobx-react-lite";
import React from "react";
import { NegativeNote, PositiveNote, useLogin, WarningNote } from "./login.utils";

export const LoginNotifications = observer(() => {
  const { form } = useLogin();
  let message = form.message && (
    <PositiveNote key="PositiveNote" loading={form.loading} kind={BannerKind.positive}>
      {form.message}
    </PositiveNote>
  );
  if (form.error) {
    message = (
      <NegativeNote key="NegativeNote" loading={form.loading} kind={BannerKind.negative}>
        {form.error}
      </NegativeNote>
    );
  } else if (form.warning) {
    message = (
      <WarningNote key="WarningNote" loading={form.loading} kind={BannerKind.warning}>
        {form.warning}
      </WarningNote>
    );
  }
  return <>{message}</>;
});
