import { Input, InputSize } from "@gadgetinc/widgets";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { FormField } from "./FormField";
import { useLogin } from "./login.utils";

export const PasswordInput = observer((props: { newPassword?: boolean; hidden?: boolean }) => {
  const { form } = useLogin();

  return (
    <FormField>
      <Input
        disabled={form.passwordDone && form.loading}
        aria-label="Password"
        placeholder="Password"
        autoFocus
        name="password"
        required={true}
        autoComplete={props.newPassword ? "new-password" : "current-password"}
        id={props.newPassword ? "new-password" : "current-password"}
        dataTestId="password"
        size={InputSize.large}
        overrides={
          props.hidden
            ? {
                Input: {
                  style: { display: "none" },
                },
              }
            : undefined
        }
        type={"password"}
        onBlur={form.setPasswordDone}
        onChange={(event) => form.setPassword((event.target as HTMLInputElement).value)}
      />
    </FormField>
  );
});
