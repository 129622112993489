import { Column, Row } from "@gadgetinc/widgets";
import { proseFamily } from "@gadgetinc/widgets/src/AuthTheme";
import { CancelIcon } from "@gadgetinc/widgets/src/icons/CancelIcon";
import { RoundedCheckmarkIcon } from "@gadgetinc/widgets/src/icons/RoundedCheckmarkIcon";
import { useStyletron } from "baseui";
import { observer } from "mobx-react-lite";
import type { ReactNode } from "react";
import * as React from "react";
import { useLogin } from "./login.utils";

export const PasswordRequirements = observer(() => {
  const { form } = useLogin();
  const [_css, $theme] = useStyletron();

  return (
    <Column $gap={$theme.sizing.scale300} $style={{ fontFamily: proseFamily, fontSize: "16px", fontWeight: 400, lineHeight: "20px" }}>
      <Requirement color={form.passwordHasSufficientLength && $theme.colors.positive600}>
        {form.passwordHasSufficientLength ? <RoundedCheckmarkIcon $size={16} /> : <CancelIcon $size={16} />} At least 8 characters
      </Requirement>
      <Requirement color={form.passwordHasNumber && $theme.colors.positive600}>
        {form.passwordHasNumber ? <RoundedCheckmarkIcon $size={16} /> : <CancelIcon $size={16} />} At least one number
      </Requirement>
      {form.passwordExceedsMaxLength && (
        <Requirement>
          <CancelIcon $size={16} /> Less than 72 characters
        </Requirement>
      )}
    </Column>
  );
});

const Requirement = (props: { children: ReactNode; color?: string | false }) => {
  const [_css, $theme] = useStyletron();
  return (
    <Row $gap={$theme.sizing.scale300} $style={{ alignItems: "center", color: props.color || $theme.colors.negative500 }}>
      {props.children}
    </Row>
  );
};
