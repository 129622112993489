import { Button, ButtonKind, ButtonSize } from "@gadgetinc/widgets";
import { observer } from "mobx-react-lite";
import React from "react";
import { useLogin } from "./login.utils";

export const ContinueWithPasswordButton = observer((props: { reset?: boolean }) => {
  const { form } = useLogin();
  const disabled = props.reset ? !form.validPassword : !form.validForm || form.loading;
  const buttonText = props.reset ? "Reset password" : form.signup ? "Create account" : "Log in";
  return (
    <Button
      data-testid="continue-with-password"
      disabled={disabled}
      kind={ButtonKind.primary}
      onClick={() => {
        if (!props.reset) {
          form.setPasswordDone();
        }
      }}
      size={ButtonSize.large}
    >
      {buttonText}
    </Button>
  );
});
